<template>
  <div>
    <modal name="expedienteEdit" height="auto" width="900" :scrollable="true" :clickToClose="false">
      <CloseModal :close="close" />
      <div class="modalbox">
        <div class="modalbox-content">
        <CTabs variant="tabs" class="nav-tabs-boxed">
          <CTab :title="`Salvando ${descSingular}`">
            <div class="xrow">
              <div class="col-1-1">
                <div class="form-group">
                  <label for="">Descrição</label>                
                  <input class="form-control" v-model="item.descricao" />
                  <div class="message">{{ validation.firstError('item.descricao') }}</div>
                </div>
              </div>
            </div>
            <ExpedienteHorario 
              v-if="item.descricao"
              :item="item.horarios"
            >
            </ExpedienteHorario>
            <div class="xrow edit-buttons">
                <button :class="{ 'button button-success': true, 'button-disabled': validation.hasError() }" v-on:click="handleSubmit">
                  Salvar
                </button>
                &nbsp;
                <button class="button" v-on:click="close">Fechar</button>
            </div>
          </CTab>
        </CTabs>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
  import { Validator } from "simple-vue-validator";
  import CloseModal from "../../../components/CloseModal";
  import ExpedienteHorario from "./ExpedienteHorarios";

  export default {
    name: 'expedienteEdit',
    props: [ 'item', 'update', 'close' , 'modulos', 'descSingular'],
    components: { CloseModal, ExpedienteHorario },
    data() {
      return {}
    },
    computed: {},
    mounted() {},
    validators: {
      "item.descricao":       (value) => Validator.value(value).required(),    
    },
    methods: {
    handleSubmit() {
      this.$validate().then((success) => {
        if (success) {
          this.update();
        }
      });
    },
    }
  }
</script>