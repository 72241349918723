<template>
  <div>
    <ExpedienteEdit
      :item="currentItem"
      :update="updateItem"      
      :close="closeEdit"
      :descSingular="descSingular"      
    ></ExpedienteEdit>
          <Loading :isActive="loading"></Loading>
          <CTabs variant="tabs" class="nav-tabs-boxed">
            <CTab :title=" descPlural ">
              <ExpedienteTable :items="items" :openEdit="openEdit" :openCreate="openCreate" :deleteConfirm="deleteConfirm"/>
            </CTab>
          </CTabs>
  </div>
</template>
<script>
import clone from 'just-clone';
import { get, post, put, del } from '@/helpers/apiRequest';
import ExpedienteEdit    from './components/ExpedienteEdit';
import ExpedienteTable   from './components/ExpedienteTable';
import _filter      from 'lodash/filter';

const Item = {
  id            : null,
  descricao     : null,
  horarios      : {
        diasemana    : null,
        expediente_id: null,
        horainicio   : null,
        horafim      : null,
        id           : null
  },
}

export default {
  name: 'expediente',
  components: {
    ExpedienteEdit,
    ExpedienteTable,
  },
  data() {
    return {
      descPlural   : '',
      descSingular : '',
      loading      : false,
      items        : [],
      modulos      : [],
      currentItem  : {},
      currentAccess: false,
    };
  },
  methods: {
    loadDescricaoModulo() {
      const { modulos } = JSON.parse(localStorage.getItem('app'));
      const { descPlural, descSingular } = modulos.find(
        (m) => m.nome === 'ADMIN_EXPEDIENTE'
      );
      this.descPlural = descPlural;
      this.descSingular = descSingular;
    },
    loadExpedientes() {
      this.loading = true;
      get('/admin/expediente')
        .then((json) => {
          this.items   = json;
          this.loading     = false;
        })
        .catch(() => (this.loading = false));
    },    
    openCreate() {
      this.currentItem = clone(Item);
      this.$modal.show('expedienteEdit');
    },
    closeCreate() {
      this.$modal.hide('expedienteEdit');
    },
    openEdit(item) {
      this.currentItem = clone(item);
      this.loading     = false;
      this.$modal.show('expedienteEdit');
    },
    closeEdit() {
      this.$modal.hide('expedienteEdit');
    },
    doRequest() {
      return this.currentItem.id ?
      put(`/admin/expediente/${this.currentItem.id}`, this.currentItem)
      :
      post('/admin/expediente', this.currentItem);
    },
    updateItem() {
      this.doRequest()
        .then((json) => {
          this.closeEdit();
          this.loadExpedientes();
          this.$swal({
            title: 'Salvo com sucesso',
            text: json.message,
            showCancelButton: false,
            cancelButtonText: 'Fechar',
            showConfirmButton: true,
            icon: 'success',
          });          
        })
        .catch((error) => {
          this.$swal({
            title: 'Falha ao Salvar',
            text: error.message,
            showCancelButton: true,
            cancelButtonText: 'Fechar',
            showConfirmButton: false,
            icon: 'error',
          });
        });
    },
    deleteConfirm(id) {
      this.$swal({
        title: 'Confirma Remover ?',
        text: 'Essa ação não poderá ser desfeita',
        showCancelButton: true,
        icon: 'warning',
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar',
      })
        .then((res) => {
          if (res.value) {
            this.loading = true;
            del(`/admin/expediente/${id}`)
              .then((json) => {
                this.loading = false;
                this.$swal({
                  title: 'Removido com sucesso',
                  icon: 'success',
                });
                this.loadExpedientes();
              })
              .catch((err) => {
                this.loading = false;
                this.$swal({
                  title: 'Falha ao remover',
                  icon: 'error',
                });
              });
          }
        })
        .catch(() => {});
    },    
  },
  beforeMount() {
    this.loading = true;
    this.loadExpedientes();
    this.loadDescricaoModulo();    
  },
};
</script>
